<template>
  <v-row class="chart-container" align="center">
    <v-col align="center">
      <v-progress-circular
        v-if="!renderChart"
        class="mt-auto"
        indeterminate
        color="primary"
        :size="100"
      ></v-progress-circular>
      <Apexchart
        v-else
        :title="chartTitle"
        :dataValues="dataValues"
        :legends="legends"
      ></Apexchart>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: [
    'metricType',
    'isChartTechnicalAssistance',
    'selectedDate',
    'chartTitle',
    'outreach_specialist_id',
    'created_by__id'
  ],
  components: {
    Apexchart: () => import('@/components/Apexchart'),
  },
  created() {
    this.date = this.selectedDate;
    this.dateChanged();
  },
  data() {
    return {
      dataValues: [],
      date: '',
      legends: [],
      menu: false,
      renderChart: false,
    };
  },
  computed: {
    ...mapGetters(['currentFilter']),
    getMinDate() {
      if (this.date.length > 1) {
        return new Date(this.date[0]) < new Date(this.date[1])
          ? new Date(this.date[0])
          : new Date(this.date[1]);
      }
      return new Date(this.date[0]);
    },
    getMaxDate() {
      if (this.date.length > 1) {
        return new Date(this.date[0]) > new Date(this.date[1])
          ? new Date(this.date[0])
          : new Date(this.date[1]);
      }
      return new Date(this.date[0]);
    },
  },
  methods: {
    ...mapActions(['fetchChartData']),
    initData(len) {
      this.dataValues = [];
      for (let i = 0; i < len; i++) this.dataValues[i] = 0;
    },
    async dateChanged() {
      const chartParams = {
        filterBy: this.currentFilter,
      };

      if (this.outreach_specialist_id >= 0) {
        chartParams['outreach_specialist_id'] = this.outreach_specialist_id;
      }
      if (this.created_by__id >= 0) {
        chartParams['created_by__id'] = this.created_by__id;
      }

      chartParams.date_created_from = this.getMinDate.toISOString().substr(0, 10);
      chartParams.date_created_to =
        this.getMaxDate != this.getMinDate
          ? this.getMaxDate.toISOString().substr(0, 10)
          : '';

      if (!this.isChartTechnicalAssistance) {
        chartParams.type = this.metricType;
      } else {
        // do something for technical assistance
      }

      await this.fetchChartData(chartParams);
      let data = this.$store.getters.chartData[this.metricType];
      // example response of date = ['2020-09','2021-11']
      if (!data) {
        data = {
          contacts: {
            '2020-09': 5, // total for month of Sept 2020
            '2020-10': 3, // total for month of Oct 2020
            '2020-11': 8, // total for month of Nov 2020
          },
          newBusinesses: {
            '2020-09': 6, // total for month of Sept 2020
            '2020-10': 6, // total for month of Oct 2020
            '2020-11': 1, // total for month of Nov 2020
          },
          businessContacted: {
            '2020-09': 3, // total for month of Sept 2020
            '2020-10': 9, // total for month of Oct 2020
            '2020-11': 6, // total for month of Nov 2020
          },

          externalReferrals: {
            '2020-09': 4, // total for month of Sept 2020
            '2020-10': 7, // total for month of Oct 2020
            '2020-11': 7, // total for month of Nov 2020
          },

          internalReferrals: {
            '2020-09': 4, // total for month of Sept 2020
            '2020-10': 2, // total for month of Oct 2020
            '2020-11': 7, // total for month of Nov 2020
          },

          technicalAssistance: {
            businessPlan: 4, // total for Sept-Nov 2020
            marketingPlan: 8, // total for Sept-Nov 2020
            proFormaFinancials: 5, // total for Sept-Nov 2020
            preUnderwriting: 9, // total for Sept-Nov 2020
          },
        };
      }

      this.legends = [];
      this.dataValues = [];
      if (!this.isChartTechnicalAssistance) {
        for (var key of Object.keys(data)) {
          if (key !== 'type') {
            this.legends.push(key);
            this.dataValues.push(data[key]);
          }
        }
      } else {
        this.legends.push('Business Plan');
        this.legends.push('Marketing Plan');
        this.legends.push('Pro-Forma-Financials');
        this.legends.push('Pre-Underwriting');
        this.dataValues = Object.values(data.technicalAssistance);
      }

      // show animation thing
      setTimeout(() => {
        this.renderChart = true;
      }, 250);
    },
    filterDates(min, max) {
      return this.$store.getters.chartData.filter((item) => {
        const date = new Date(item.createdAt);
        return date >= min && date < max;
      });
    },
    filterByWeek(filteredDates) {
      // change x-axis labels
      for (let i = 1; i <= 4; i++) {
        this.legends.push(`Week ${i}`);
      }
      for (let i = 0; i < filteredDates.length; i++) {
        const day = parseInt(filteredDates[i].createdAt.substring(8));
        const week = Math.floor(day / 7);
        const value = filteredDates[i][this.metricType].value;
        if (day % 7 === 0) {
          this.dataValues[week - 1] = this.dataValues[week - 1] + value;
        } else {
          this.dataValues[week] = this.dataValues[week] + value;
        }
      }
    },
    filterByMonth(filteredDates) {
      filteredDates.forEach((item, index) => {
        const i = Math.floor(index / 28);
        // add data
        this.dataValues[i] = this.dataValues[i] + parseInt(item[this.metricType].value);
        // add labels
        if (index % 28 === 0) this.legends.push(item.createdAt.substring(0, 7));
      });
    },
    filterByYear(filteredDates, min) {
      filteredDates.forEach((item) => {
        const year = item.createdAt.substring(0, 4);
        const index = parseInt(year) - parseInt(min);
        // add data
        this.dataValues[index] =
          this.dataValues[index] + parseInt(item[this.metricType].value);
      });
    },
    addTechnicalAssistanceData(filteredDates) {
      this.initData(4);
      this.legends.push(filteredDates[0]['businessPlan'].name);
      this.legends.push(filteredDates[0]['marketingPlan'].name);
      this.legends.push(filteredDates[0]['proFormaFinancials'].name);
      this.legends.push(filteredDates[0]['preUnderwriting'].name);
      filteredDates.forEach((item) => {
        this.dataValues[0] += item['businessPlan'].value;
        this.dataValues[1] += item['marketingPlan'].value;
        this.dataValues[2] += item['proFormaFinancials'].value;
        this.dataValues[3] += item['preUnderwriting'].value;
      });
    },
  },
};
</script>

<style scoped>
.chart-color {
  background-color: #dbb42b;
}
.chart-container {
  min-height: 300px;
}
</style>
